// 定义 AdminDropDownMenu 组件
const AdminDropDownMenu = ({ itemList = [], onItemClick, selectedMenuItem = "All" }) => {
    // 在 itemList 中动态添加 "All" 选项
    const listWithAll = ["All", ...itemList.sort()];


    return (
        <div className="h-[332px] w-[184px] py-2 rounded-md overflow-auto text-sm border border-solid" style={{ borderColor: '#00000014' }}>
            {Array.isArray(listWithAll) && listWithAll.length > 0 ? (
                // 显示菜单项列表
                listWithAll.map((item, index) => (
                    <div
                        key={index}
                        className={`w-full h-9 py-2 px-4 cursor-pointer font-normal hover:bg-gray-100 ${
                            selectedMenuItem === item ? 'text-[#ff6700] bg-custom-gradient' : 'text-neutral-700 bg-white'
                        }`}
                        onClick={() => onItemClick(item)}  // 点击菜单项时调用 onItemClick
                    >
                        {item}
                    </div>
                ))
            ) : (
                // 如果没有菜单项，显示提示信息
                <div className="text-center text-neutral-500 py-2">No items available</div>
            )}
        </div>
    );
};

export default AdminDropDownMenu;
