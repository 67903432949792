import React, { useState } from 'react'
import { Form, Input, Button, Card, Toast } from 'antd-mobile'
import './index.scss'
import FooterNav from '@/componets/FooterNav/FooterNav.js'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchSignUp, fetchPostVerifyCode } from '@/store/modules/private.js'
import CheckMark from '@/componets/CheckMark/Checkmark.js'
import FailedIcon from '@/componets/FailedIcon/FailedIcon.js'

const SignUp = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [formDate, setFormDate] = useState({})
  const [cardVisible, setCardVisible] = useState(false)
  const [maskVisible, setMaskVisible] = useState(false)

  const dispatch = useDispatch()

  const onFinish = async (values) => {
    const password1 = form.getFieldValue('password_1')
    const password2 = form.getFieldValue('password_2')

    if (password1 !== password2) {
      Toast.show({
        content: 'The two passwords do not match!',
        position: 'top',
        icon: <FailedIcon />,
        duration: 3000
      })
      return
    }

    setFormDate(values)
    const res = await dispatch(fetchSignUp(values))
    if (res.success) {
      setCardVisible(true) // 显示卡片
      setMaskVisible(true) // 显示遮罩层
    } else {
      Toast.show({
        content: res.message,
        position: 'top',
        icon: <FailedIcon />,
        duration: 3000
      })
    }
  }

  const onClick = () => {
    //
  }

  // 卡片表单验证按钮
  const cardFormFinish = async (value) => {
    value.email = formDate.email
    const formData = value
    const res = await dispatch(fetchPostVerifyCode(formData))
    if (res.success) {
      Toast.show({
        icon: <CheckMark />,
        duration: 3000
      })
      setCardVisible(false) // 隐藏卡片
      setMaskVisible(false) // 隐藏遮罩层
      navigate('/login')
    } else {
      Toast.show({
        content: res.message,
        icon: <FailedIcon />,
        duration: 3000,
        position: 'top'
      })
    }
  }

  // 卡片取消按钮
  const cardBtnCancelHandler = () => {
    setCardVisible(false) // 隐藏卡片
    setMaskVisible(false) // 隐藏遮罩层
  }

  return (
    <div className="signup-container-div">
      {/* 遮罩层 */}
      <div className={`signup-wrapper ${maskVisible ? 'masked' : ''}`}>
        <div className="signup-container">
          <h2>Sign Up</h2>
          {/* sign up 表单 */}
          <Form
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            validateTrigger="onBlur"
            footer={
              <Button
                block
                type="submit"
                color="primary"
                size="large"
                className="signup-button"
              >
                Sign Up
              </Button>
            }
          >
            <div className="name-fields">
              <div className="field-container">
                <div className="field-label">First Name*</div>
                <Form.Item
                  name="first_name"
                  rules={[
                    { required: true, message: 'First Name is required' },
                    {
                      pattern: /^[A-Z][a-zA-Z\s]{1,49}$/,
                      message: 'First Name must start with an uppercase letter'
                    }
                  ]}
                >
                  <Input id="first_name" placeholder="Your first name" />
                </Form.Item>
              </div>
              <div className="field-container">
                <div className="field-label">Last Name*</div>
                <Form.Item
                  name="last_name"
                  rules={[
                    { required: true, message: 'Last Name is required' },
                    {
                      pattern: /^[A-Za-z\s]{2,50}$/,
                      message:
                        'Last Name must contain only letters and spaces, and be 2 to 50 characters long.'
                    }
                  ]}
                >
                  <Input id="last_name" placeholder="Your last name" />
                </Form.Item>
              </div>
            </div>

            <div className="field-container">
              <div className="field-label">Email*</div>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Email is required' },
                  { type: 'email', message: 'The input is not valid E-mail!' }
                ]}
              >
                <Input id="email" placeholder="Your email" />
              </Form.Item>
            </div>

            <div className="field-container">
              <div className="field-label">Password*</div>
              <Form.Item
                name="password_1"
                rules={[
                  { required: true, message: 'Password is required' },
                  { min: 8, message: 'Password must be at least 8 characters' },
                  {
                    max: 16,
                    message: 'Password cannot be longer than 16 characters'
                  }
                ]}
              >
                <Input
                  id="password_1"
                  type="password"
                  placeholder="Enter password"
                />
              </Form.Item>
            </div>

            <div className="field-container">
              <div className="field-label">Re-Enter Password*</div>
              <Form.Item
                name="password_2"
                rules={[
                  { required: true, message: 'Password is required' },
                  { min: 8, message: 'Password must be at least 8 characters' },
                  {
                    max: 16,
                    message: 'Password cannot be longer than 16 characters'
                  }
                ]}
              >
                <Input
                  id="password_2"
                  type="password"
                  placeholder="Re-enter password"
                />
              </Form.Item>
            </div>
          </Form>

          {/* login页面跳转 */}
          <p className="login-link">
            Already have an account? <a href="/login">Login here</a>
          </p>
        </div>
        {maskVisible && <div className="mask"></div>}

        {/* 弹框卡片区域 */}
        {cardVisible && (
          <div className="card-overlay">
            <Card
              title={
                <span
                  style={{
                    fontSize: 12 + 'px',
                    color: '#333',
                    textAlign: 'center'
                  }}
                >{`Find your verification code from this email address: ${formDate.email}`}</span>
              }
              onClick={onClick}
            >
              {/* 卡片表单区域 */}
              <Form
                layout="horizontal"
                onFinish={cardFormFinish}
                validateTrigger="onBlur"
                // 取消按钮和 注册验证按钮
                footer={
                  <div className="btn_flex">
                    <button
                      className="cardCancel-button"
                      onClick={cardBtnCancelHandler}
                    >
                      Cancel
                    </button>
                    <Button
                      block
                      type="submit"
                      color="primary"
                      size="large"
                      className="verify-button"
                    >
                      Verify
                    </Button>
                  </div>
                }
              >
                {/* 验证码输入表单区域 */}
                <Form.Item
                  name="code"
                  rules={[
                    { required: true, message: 'Please enter your code!' },
                    {
                      pattern: /^[0-9]{6}$/,
                      message: 'The code must be exactly 6 digits!'
                    }
                  ]}
                >
                  <Input id="code" type="text" placeholder="enter your code" />
                </Form.Item>
              </Form>
            </Card>
          </div>
        )}
      </div>

      {/* 底下的文字区域 */}
      <div className="bottom_contect">*Mandatory Fields</div>
      <FooterNav />
    </div>
  )
}

export default SignUp
