//封装token相关的方法
const TOKEN_KEY = 'token_key'
function _setToken(token) {
  sessionStorage.setItem(TOKEN_KEY, token)
}

function getToken() {
  return sessionStorage.getItem(TOKEN_KEY)
}

function removeToken() {
  sessionStorage.removeItem(TOKEN_KEY)
}

// 将用户信息存储到 sessionStorage
function _setUserInfo(userInfo) {
  if (typeof userInfo === 'object' && userInfo !== null) {
    // 序列化对象并存储
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  } else {
    console.error('Invalid userInfo: it should be a non-null object');
  }
}

// 从 sessionStorage 获取并反序列化用户信息
function getUserInfo() {
  const userInfo = sessionStorage.getItem('userInfo');
  
  if (userInfo) {
    try {
      // 反序列化为对象并返回
      return JSON.parse(userInfo);
    } catch (error) {
      console.error('Error parsing userInfo from sessionStorage', error);
      return null;
    }
  }
  
  return null; // 如果 sessionStorage 中没有用户信息，返回 null
}

function removeUserInfo() {
  sessionStorage.removeItem('userInfo')
}


export { _setToken, getToken, removeToken,_setUserInfo,getUserInfo,removeUserInfo }
