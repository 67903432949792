import { request } from '@/utils/index.js' // 引入封装好的 axios 实例

// 获取所有策略数据
const getStrategySummaryAll = () => {
  return request({
    url: 'https://api.stoneriver.io/api/strategy_summary_all',
    method: 'GET'
  })
}

// 获取特定策略名称的事实表
const getFactSheet = (strategy) => {
  return request({
    url: `https://api.stoneriver.io/api/fact_sheet?strategy_name=${strategy}`,
    method: 'GET'
  })
}

const getFactSheetV2 = (strategy) => {
  return request({
    url: `https://api.stoneriver.io/api/fact_sheet?strategy_name=${strategy}`,
    method: 'GET'
  })
}


const getStrategyHistoryV2 = (strategy, timeWindow) => {
  return request({
    url: `https://api.stoneriver.io/api/strategy_history?product_name=${strategy}&time_window=${timeWindow}`,
    method: 'GET'
  })
}

// 联系我们页面
const contactUs = (formData) => {
  return request({
    url: 'https://api.stoneriver.io/contact_info',
    method: 'POST',
    data: formData
  })
}


const getStrategyDiscription = (name) => {
  return request({
    url:`https://api.stoneriver.io/api/description?strategy_name=${name}`,
    method:"GET",
  })
}


export {getFactSheet, contactUs,getStrategySummaryAll,getFactSheetV2,getStrategyHistoryV2,getStrategyDiscription}
