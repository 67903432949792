import { Navigate, useLocation } from 'react-router-dom';
import { getToken } from '@/utils/index.js';

const ProtectedRoute = ({ children }) => {
  const token = getToken();
  const location = useLocation();

  if (!token) {
    // 如果没有 token，跳转到登录页，并记录用户从哪里来
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // 如果有 token，渲染子组件
  return children;
};

export default ProtectedRoute;
