import React, { useState, useEffect } from 'react';
import './NavBar.scss';
import { useNavigate, Link } from 'react-router-dom';
import { removeToken, getToken, removeUserInfo } from '@/utils/token.js';
import DropdownMenu from '@/componets/DropDownMenu/DropDownMenu.js';
import GradientButton from '@/componets/Button/Button.js';
import { useSelector } from 'react-redux';
import { fetchGetStrategySummaryV2 } from '@/store/modules/public';
import { useDispatch } from 'react-redux';
const NavBar = () => {
  const navigate = useNavigate();
  const [menuItemVisible, setMenuItemVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [strategiesVisible, setStrategiesVisible] = useState(false);

  const dispatch = useDispatch()
  
  // 用于存储策略导航链接
  const [navigationLinks, setNavigationLinks] = useState([]);

  // 从 Redux 中获取策略数据
  const strategyTitles = useSelector((state) => state.public.strategySummaryAll.data);

  // 使用 useEffect 来确保 strategyTitles 加载完成并创建 navigationLinks
  useEffect(() => {
    if (strategyTitles && strategyTitles.length > 0) {
      const links = strategyTitles.map(item => ({
        title: item.product_name,
        path: `/strategy?strategy=${item.product_name}`
      }));
      setNavigationLinks(links);
    }
  }, [strategyTitles]);

  useEffect(() => {
    dispatch(fetchGetStrategySummaryV2());
  }, [dispatch]); 


  const Portfilio = [
    { title: 'profit', path: '/profit' },
    { title: 'bills', path: '/bills' },
    { title: 'profile', path: '/profile' }
  ];

  const token = getToken();

  const btnHandler = () => {
    if (token) {
      removeToken();
      removeUserInfo()
      navigate('/login');
    } else {
      navigate('/login');
    }
  };

  const handleMenuClick = () => {
    setMenuVisible((prevState) => {
      const newState = !prevState;

      if (newState) {
        setTimeout(() => {
          setMenuVisible(false);
        }, 5000);
      }
      return newState;
    });
  };

  const handleLinkClick = () => {
    setMenuVisible(false);
  };

  return (
    <div className="navBar_container">
      <div className="left_menu_icon">
        {/*菜单栏实现 */}
        <div className={`menu-container ${menuVisible ? 'visible' : ''}`}>
          <div className="menu-icon" onClick={handleMenuClick}>
            &#9776;
          </div>
          {menuVisible && (
            <div className="dropdown-menu">
              {navigationLinks && navigationLinks.map((item) => {
                return ( <Link to={item.path} onClick={handleLinkClick} key={item.title}>
                  {item.title}
                </Link>)
              })}
            </div>
          )}
        </div>
        <div
          className="img"
          onClick={() => {
            navigate('/');
          }}
        ></div>
        <div
          className="pc_right_title lexend-font"
          onClick={() => {
            navigate('/');
          }}
        >
          STONERIVER TECHNOLOGY
        </div>
      </div>

      <div className="right_btn">
        <div className="pc_Strategies_menu">
          <div
            className="pc_contact_strategies"
            onClick={() => {
              setStrategiesVisible((prevState) => {
                const newState = !prevState;

                if (newState) {
                  setTimeout(() => {
                    setStrategiesVisible(false);
                  }, 5000);
                }

                return newState;
              });
            }}
          >
            <div
              className="pc_navbar_strategies"
              style={{ fontSize: 14 + 'px', fontWeight: 500 }}
            >
              Strategies
            </div>
            <span className="arrow-bottom"></span>
            {/* 仅当 navigationLinks 不为空时渲染 DropdownMenu */}
            {strategiesVisible && navigationLinks.length > 0 && (
              <DropdownMenu pathdic={navigationLinks} />
            )}
          </div>
        </div>

        <div className="pc_contact_us" onClick={() => navigate('/contact-us')}>
          Contact Us
        </div>
        {token && (
          <div className="myPortfilio_btn_flex">
            <div
              className="navbar-myPortfilio"
              onClick={() => {
                setMenuItemVisible((prevState) => {
                  const newState = !prevState;

                  if (newState) {
                    setTimeout(() => {
                      setMenuItemVisible(false);
                    }, 5000);
                  }

                  return newState;
                });
              }}
            >
              <div className="navbar_profilio">My Portfilio</div>
              <span className="arrow-bottom"></span>
              {menuItemVisible && <DropdownMenu pathdic={Portfilio} />}
            </div>
          </div>
        )}
        <GradientButton
          onClick={btnHandler}
          text={token ? 'Log out' : 'Login'}
          width="86px"
          height="36px"
        />
      </div>
    </div>
  );
};

export default NavBar;
