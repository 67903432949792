import React, { Suspense, lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Loading from '@/componets/Loading/Loading.js';
import Admin from '@/pages/admin.js';
import Encrypt from '@/pages/Encrypt';

import Login from '@/pages/Login/index.js';
import Layout from '@/pages/Layout/index.js';
import Home from '@/pages/Home/index.js';
import SignUp from '@/pages/SignUp/index.js';
import ContactUs from '@/pages/ContactUs/index.js';
import Faq from '@/pages/Faq/index.js';
import Disclaimer from '@/pages/Disclaimer/index.js';
import Term from '@/pages/Term/index.js';
import ProtectedRoute from '@/componets/ProtectedRoute'; // 引入 ProtectedRoute

const Strategy = lazy(() => import('@/pages/Strategy/index.js'));
const Profit = lazy(() => import('@/pages/Profit/index.js'));
const Bills = lazy(() => import('@/pages/Bills/index.js'));
const Profile = lazy(() => import('@/pages/Profile/index.js'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <Suspense fallback={<Loading />}>
            <Home />
          </Suspense>
        )
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/signup',
        element: <SignUp />
      },
      {
        path: '/contact-us',
        element: <ContactUs />
      },
      {
        path: '/faq',
        element: <Faq />
      },
      {
        path: '/disclaimer',
        element: <Disclaimer />
      },
      {
        path: '/terms-and-conditions',
        element: <Term />
      },
      {
        path: '/strategy',
        element: (
          <Suspense fallback={<Loading />}>
            <Strategy />
          </Suspense>
        )
      },
      {
        path: '/profit',
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading />}>
              <Profit />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: '/bills',
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading />}>
              <Bills />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: '/profile',
        element: (
          <ProtectedRoute>
            <Suspense fallback={<Loading />}>
              <Profile />
            </Suspense>
          </ProtectedRoute>
        )
      },
      {
        path: '/admin',
        element: (
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        )
      },
      {
        path:'/crypt',
        element: (
          <ProtectedRoute>
            <Encrypt />
          </ProtectedRoute>
        )
      }
    ]
  }
]);

export default router;
