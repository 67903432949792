import React, { useEffect, useState } from 'react';
import './index.scss';
import FooterNav from '@/componets/FooterNav/FooterNav.js';
import Loading from '@/componets/Loading/Loading.js';
import { useSelector, useDispatch } from 'react-redux';
import { fetchGetStrategySummaryV2 } from '@/store/modules/public';
import HomeStrategyCard from '@/componets/HomeStrategyCard';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // 从 Redux 中获取策略数据的 state
  const strategySummaryAll = useSelector((state) => state.public.strategySummaryAll);

  // 控制加载状态
  const [isLoading, setIsLoading] = useState(true);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [navigationLinks, setNavigationLinks] = useState([]);



  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // 等待 API 请求完成
        await dispatch(fetchGetStrategySummaryV2());
        setIsDataLoaded(true);
      } catch (error) {
        setIsDataLoaded(false);
      } finally {
        setIsLoading(false);
      }
    };

    // 如果没有加载数据才发起请求，避免重复请求
    if (!isDataLoaded) {
      fetchData();
    }
  }, [dispatch, isDataLoaded]);

  useEffect(() => {
    if (strategySummaryAll && strategySummaryAll.data && strategySummaryAll.data.length > 0) {
      // 只有在 strategySummaryAll 有数据时才生成 navigationLinks
      const links = strategySummaryAll.data.map(item => ({
        title: item.product_name,
        path: `/strategy?strategy=${item.product_name}`
      }));
      setNavigationLinks(links);
    }
  }, [strategySummaryAll]);

  const handleCardClick = (item) => {
    // 根据 item.product_name 找到对应的 navigationLink 对象
    const selectedLink = navigationLinks.find(link => link.title === item.product_name);
  
    // 如果找到对应的 path，则进行导航跳转
    if (selectedLink) {
      navigate(selectedLink.path);
    } 
  };
  

  // 在加载时显示加载组件
  if (isLoading) {
    return <Loading />;
  }

  // 如果没有加载到数据，则不渲染页面
  if (!strategySummaryAll || !strategySummaryAll.data || strategySummaryAll.data.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="top_part">
        <div className="gradient-text second-liner-text">
          Non-custodial crypto trading strategies
        </div>
        <div className="num_doller">
          <span className="span">${strategySummaryAll.total_aum.toLocaleString('en-US').split('.')[0]}</span>{' '}
          invested across {strategySummaryAll.strategy_number} strategies
        </div>
      </div>

      {/* 显示策略卡片 */}
      <div className="grid max-w-[1104px] m-10 my-10 gap-10 md:mx-auto grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-items-center">
        {strategySummaryAll.data.map((item, index) => (
          <HomeStrategyCard key={index} item={item} onClick={() => handleCardClick(item)} />
        ))}
      </div>

      <div className="outer">
        <div className="icon_gray"></div>
        <div className="outer_content_div_flex">
          <div className="content_div">
            StoneRiver Technology’s Strategies lets you tap into proven arbitrage methods from the safety of your own trading account.
          </div>
        </div>
      </div>

      <div className="outer_small_div">
        <div className="small_content_1">*7-days average APY</div>
        <div className="small_content_2">
          **Top coins by open interest in derivate contracts of the coins
        </div>
      </div>

      {/* Footer区域 */}
      <FooterNav />
    </div>
  );
};

export default Home;
