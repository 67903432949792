import React from 'react'
import { useNavigate } from 'react-router-dom'
import './DropDownMenu.scss'
import { useSelector } from 'react-redux'

const DropdownMenu = ({ pathdic }) => {
  const navigate = useNavigate()
  console.log(pathdic)

  const allStrategyData = useSelector(
    (state) => state.public.strategy_summary_all
  )

  console.log(allStrategyData)
  const handleNavigation = (path) => {
    navigate(path)
  }

  return (
    <div className="dropdown">
      {
        <div className="dropdown-menu">
          {pathdic.map((item) => (
            <div
              className="dropdown-item"
              key={item.title}
              onClick={() => handleNavigation(item.path)}
            >
              {item.title}
            </div>
          ))}
        </div>
      }
    </div>
  )
}

export default DropdownMenu
