import React, { useState } from 'react';
import btcIcon from '@/assets/btc.svg';
import ethIcon from '@/assets/eth.svg';
import usdtIcon from '@/assets/usdt.svg';

const HomeStrategyCard = ({ item ,onClick}) => {
  // 跟踪鼠标悬停状态
  const [isHovered, setIsHovered] = useState(false);

  // 根据 quote_coin 字段选择相应的图标
  const getIcon = (coin) => {
    switch (coin) {
      case 'BTC':
        return btcIcon;
      case 'ETH':
        return ethIcon;
      case 'USDT':
        return usdtIcon;
      default:
        return null; // 可以根据需求设置默认图标或为空
    }
  };


  return (
    <div
      className={`w-[334px] h-[184px] rounded-2xl shadow-home-card-shadow bg-custom-gradient flex flex-col justify-between items-center py-4 
      ${isHovered ? 'home-gradient-border' : 'border border-transparent'}`} // 添加非悬停状态为透明边框
      onMouseEnter={() => setIsHovered(true)} // 当鼠标进入时
      onMouseLeave={() => setIsHovered(false)} // 当鼠标离开时
      onClick = {onClick}
    >
      <div className="w-[294px] h-[40px] flex flex-row justify-between items-center">
        <div className="text-custom-black text-2xl font-semibold ">
          {item.product_name}
        </div>
        <img
          src={getIcon(item.quote_coin)} // 根据 quote_coin 动态渲染图标
          className="w-[40px] h-[40px]"
          alt="currency_icon"
        />
      </div>
      <div className="w-[294px] h-[100px] flex flex-col justify-between items-center">
        <div className="h-9 w-[294px] flex items-baseline">
          <span className="text-3xl font-semibold text-[#6939BD]">
            {(item.apy * 100).toFixed(2)}%
          </span>
          <span className="text-sm text-gray-700 ml-2">APY*</span>
        </div>

        <div className="h-14 w-[294px] flex flex-col justify-between">
          <div className="flex justify-between items-start h-7">
            <span className="text-sm text-[#929292] font-normal">
              Net Asset Value
            </span>
            <span className="text-sm text-custom-black font-normal">
              {item.net_asset_value}
            </span>
          </div>
          <div className="flex justify-between items-start h-7">
            <span className="text-sm text-[#929292] font-normal">AUM</span>
            <span className="text-sm text-custom-black font-normal">
              {`${item.aum.toLocaleString('en-US')}  ${item.quote_coin}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeStrategyCard;
