import lockedSvg from '@/assets/locked.svg';
import unlockedSvg from '@/assets/unlocked.svg';
import { getUserInfo } from '@/utils/index.js';
import { useState, useEffect } from 'react';

const TextFieldAndLock = ({ userItem, onImageClick,clearText, onClear}) => {
    const userInfo = getUserInfo();
    const CurrentUser = userInfo.first_name === "Zixu" ? "Vincent" : userInfo.first_name 
    
    const [CurrentUserInputText, setCurrentUserInputText] = useState(""); // 最终更新的文本
    const [inputValue, setInputValue] = useState(""); // 临时存储用户输入的值

    

    // 节流：在用户停止输入超过 0.3 秒后更新 CurrentUserInputText
    useEffect(() => {
        const handler = setTimeout(() => {
            setCurrentUserInputText(inputValue); // 更新最终文本内容
        }, 300);

        return () => {
            clearTimeout(handler); // 如果用户在 0.3 秒内继续输入，清除定时器
        };
    }, [inputValue]);

    // 处理文本框内容变化
    const handleInputChange = (event) => {
        setInputValue(event.target.value); // 更新临时输入值
    };

    useEffect(() => {
        if (clearText) {
            setInputValue(""); // 清空输入框内容
            setCurrentUserInputText(""); // 清空节流后的最终内容
            onClear(); // 重置 clearText 状态
        }
    }, [clearText, onClear]);

    return (
        <div className="w-[650px] h-[180px] flex flex-row justify-between items-center p-2">
            <div className="w-[500px] h-[160px] bg-[#fdeede] rounded-3xl p-4">
                <div className="w-full text-center text-xl font-semibold">{userItem.username}</div>
                <div>
                    <textarea
                        className={`w-full h-[85px] mt-2 p-2 rounded-3xl resize-none border border-[#c6c4c4] focus:outline-none 
                        ${CurrentUser !== userItem.username ? 'bg-[#C6C4C4]' : 'bg-[#FBFBFB]'}`}
                        placeholder={CurrentUser !== userItem.username ? "You can't input" : "Input text here..."}
                        disabled={CurrentUser !== userItem.username} // 条件判断
                        value={inputValue} // 设置 textarea 的值为临时输入值
                        onChange={handleInputChange} // 当内容改变时调用
                    ></textarea>
                </div>
            </div>

            <img
                src={userItem.status ? unlockedSvg : lockedSvg}
                alt=""
                className="w-20 h-20 cursor-pointer"
                onClick={() => {
                    if (CurrentUser === userItem.username) {
                        onImageClick(userItem, CurrentUserInputText);
                    }
                }}
            />
        </div>
    );
};

export default TextFieldAndLock;
