import TextFieldAndLock from "@/componets/TextFieldAndLock"
import { fetchGetCryptStatusAPI, fetchSendCryptKeyAPI } from "@/store/modules/private"
import { fetchGetCryptUnlocksAPI } from "@/store/modules/private"
import { message } from "antd"
import { useState } from "react"


import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux";

const Encrypt = () => {

    const dispatch = useDispatch()

    const [returnedCryptMessage, setReturnedCryptMessage] = useState("")
    const [clearText, setClearText] = useState(false);

    const {
        usersLocksStatus
    } = useSelector((state) => state.private);




    useEffect(() => {
        const fetchCryptStatusData = async () => {
            try {
                await dispatch(fetchGetCryptStatusAPI());
                // 可以在这里处理 res 的后续逻辑
            } catch (error) {
                console.error("Error fetching crypto status:", error);
            }
        };

        // 设置每隔 1 秒调用一次 fetchCryptStatusData
        const intervalId = setInterval(fetchCryptStatusData, 1000);

        // 清除定时器以防止内存泄漏
        return () => clearInterval(intervalId);
    }, [dispatch]);


    // 定义点击处理函数
    const handleImageClick = (clickedUserItem, inputValue) => {
        console.log("Clicked user item:", clickedUserItem);
        console.log(inputValue)
        if (inputValue.trim()) {  // 检查 inputValue 是否不为空且去除空格
            const fetchSendCryptKey = async () => {
                try {
                    const res = await dispatch(fetchSendCryptKeyAPI(inputValue));
                    if (res.success) {
                        message.success(res.message)
                        setClearText(true);
                    } else {
                        message.error(res.message)
                    }
                } catch (error) {
                    console.error("Error fetching crypto unlocked:", error);
                }
            };

            fetchSendCryptKey();
        } else {
            message.error("The sent key cannot be empty!");
        }
    };

    const areAllUsersUnlocked = (usersLocksStatus) => {
        return usersLocksStatus.every(user => user.status === true);
    };


    const handlerUnlockBtnClicked = () => {
        const res = areAllUsersUnlocked(usersLocksStatus)
        if (res) {
            const fetchCryptUnlocledData = async () => {
                try {
                    const res = await dispatch(fetchGetCryptUnlocksAPI());
                    if (res?.success) {
                        message.success("Success!!")
                        setReturnedCryptMessage(res.data)
                    } else {
                        message.error(res?.message)
                    }


                } catch (error) {
                    console.error("Error fetching crypto unlocled:", error);
                }
            };
            fetchCryptUnlocledData();
        } else {
            message.error("Need all three of you to unlock.")
        }
    }


    return (
        <div className="h-[calc(100vh-67px)] w-full bg-encryptPageCustomGradient flex justify-center items-center">
            <div className="w-[1200px] h-[640px] flex">
                {usersLocksStatus?.length > 0 ? (
                    <>
                        <div className="w-[680px] h-[640px] flex flex-col justify-between items-center py-4">
                            {usersLocksStatus.map((item) => (
                                <div key={item.username}>
                                    <TextFieldAndLock
                                        userItem={item}
                                        onImageClick={handleImageClick}
                                        onClear={() => setClearText(false)}
                                        clearText={clearText}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="w-[520px] h-[640px] flex justify-center items-center">
                            <div className="w-[400px] h-[300px] bg-[#fdeede] rounded-3xl p-4">
                                <div>
                                    <div className="w-full h-[200px] mt-2 p-2 bg-[#FBFBFB] rounded-3xl resize-none border border-[#c6c4c4]">
                                        {returnedCryptMessage && returnedCryptMessage}
                                    </div>
                                </div>
                                <div className="w-full flex justify-center items-center mt-4">
                                    <button
                                        className="w-[100px] h-[40px] rounded-full text-white bg-[#2f88ff] text-center text-lg font-semibold"
                                        onClick={handlerUnlockBtnClicked}
                                    >
                                        Unlock
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="w-[1200px] h-[640px] flex justify-center items-center">
                        <div className="text-3xl font-semibold text-white">Loading...</div>
                    </div>
                )}

            </div>

        </div>
    )
}

export default Encrypt