import { createSlice } from '@reduxjs/toolkit'
import {
  getFactSheet,
  contactUs,
  getStrategySummaryAll,
  getFactSheetV2,
  getStrategyHistoryV2,
  getStrategyDiscription
} from '@/apis/public'

const publicStore = createSlice({
  name: 'public',
  initialState: {
    // fact_sheet: {},
    strategy_historyV2:[],
    strategySummaryAll: [],
    fact_sheetV2 : [],
    discriptionList : []
  },
  reducers: {
    setStrategySummaryAllV2(state, action) {
      state.strategySummaryAll = action.payload
    },
    setFactSheet(state, action) {
      state.fact_sheet = action.payload
    },
    setFactSheetV2(state, action) {
      state.fact_sheetV2 = action.payload
    },
    setStrategyHistoryV2(state, action) {
      state.strategy_historyV2 = action.payload
    },
    setStrategyApyValues(state, action) {
      state.strategy_apy_values = action.payload
    },
    setDiscription(state,action) {
      state.discriptionList = action.payload
    }
  }
})

const {
  setFactSheet,
  setStrategySummaryAllV2,
  setFactSheetV2,
  setStrategyHistoryV2,
  setDiscription
} = publicStore.actions



const fetchGetStrategySummaryV2 = () => {
  return async (dispatch) => {
    try {
      const res = await getStrategySummaryAll()
      if(res && res?.success === true){
        dispatch(  setStrategySummaryAllV2(res.data))
      }
    } catch (error) {
      console.error(error.message)
    }
  }
}

// 异步 thunk 获取特定策略名称的事实表
const fetchGetFactSheet = (strategy) => {
  return async (dispatch) => {
    try {
      const res = await getFactSheet(strategy)
      dispatch(setFactSheet(res))
    } catch (error) {
      console.error(error.message)
    }
  }
}

const fetchGetFactSheetV2 = (strategy) => {
  return async (dispatch) => {
    try {
      const res = await getFactSheetV2(strategy)
      if(res?.success === true && res?.message === "success"){
        dispatch(setFactSheetV2(res.data))
      }
      
    } catch (error) {
      console.error(error.message)
    }
  }
}



const fetchGetStrategyHistoryV2 = (strategy, timeWindow) => {
  return async (dispatch) => {
    try {
      const res = await getStrategyHistoryV2(strategy, timeWindow)
      if(res && res.success === true) {
        dispatch(setStrategyHistoryV2(res.data))
      }
     
    } catch (error) {
      console.error(error.message)
    }
  }
}

// 异步 thunk 提交 contact us 表单信息
const fetchPostContactUs = (formData) => {
  return async () => {
    try {
      const res = await contactUs(formData)
      if (res.message === 'Contact information received successfully') {
        return { success: true, message: res.message }
      } else {
        return { success: false, message: 'Incorrect Data' }
      }
    } catch (error) {
      return { success: false, message: error }
    }
  }
}

const fetchGetDescription = (name) => {
  return async (dispatch) => {
    try {
      const res = await getStrategyDiscription(name)
      if (res.message === "success" && res?.success === true) {
        dispatch(setDiscription(res.data))
      } 
    } catch (error) {
      return 
    }
  }
}

const publicReducer = publicStore.reducer

export {
  fetchGetFactSheet,
  fetchPostContactUs,
  fetchGetStrategySummaryV2,
  fetchGetFactSheetV2,
  fetchGetStrategyHistoryV2,
  fetchGetDescription
}

export default publicReducer
